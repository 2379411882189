var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inhalt",attrs:{"id":"inhalt"}},[_c('div',{staticClass:"inhalt__wrapper"},[_c('h1',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
        delay: 250,
        origin: 'left',
        distance: '50px',
      }),expression:"{\n        delay: 250,\n        origin: 'left',\n        distance: '50px',\n      }",modifiers:{"reset":true}}],domProps:{"innerHTML":_vm._s(_vm.$texte.inhalt.h1)}}),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({ delay: 500, origin: 'left', distance: '50px' }),expression:"{ delay: 500, origin: 'left', distance: '50px' }",modifiers:{"reset":true}}],staticClass:"inhalt__text",class:_vm.$mq,domProps:{"innerHTML":_vm._s(_vm.$texte.inhalt.text)}}),_c('BaseImage',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
        delay: 500,
        origin: 'right',
        distance: '100px',
      }),expression:"{\n        delay: 500,\n        origin: 'right',\n        distance: '100px',\n      }",modifiers:{"reset":true}}],staticClass:"inhalt__image",attrs:{"image":"inhalt/picture.jpg","text":"Selbstporträt, Monte Carlo, 1993","copyright":"© Foto: Helmut Newton, Helmut Newton Estate / Courtesy Helmut Newton Foundation"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }