<!-- @format -->
<template>
  <BaseScrollActive class="base-navigation">
    <template v-for="(value, key) in this.$config.navigation">
      <!-- eslint-disable-next-line -->
      <a
        :href="'#' + value.sitename"
        v-if="key !== 'Trailer'"
        class="nav-item scrollactive-item"
        v-scroll-to="{
          el: '#' + value.sitename,
          offset: -44,
        }"
      >
        {{ key }}
      </a>
      <!-- eslint-disable-next-line -->
      <div
        v-if="key === 'Trailer'"
        @click="yt_video"
        class="nav-item open-trailer"
      >
        Trailer
      </div>
    </template>
  </BaseScrollActive>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    data() {
      return {
        headerHeight: this.$headerheight,
      }
    },
    mounted() {
      this.$nextTick(function () {
        this.headerHeight = this.$headerheight
      })
    },
    methods: {
      yt_video() {
        EventBus.$emit('open-yt')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .base-navigation {
    display: flex;
    flex-direction: row;
    .nav-item {
      position: relative;
      text-transform: uppercase;
      height: $header-height;
      line-height: $header-height;
      padding-right: $header-link-padding;
      color: $header-link;
      font-weight: $header-fontweight;
      cursor: pointer;
      $min_width: 1024px;
      $max_width: 2560px;
      $min_font: 16px;
      $max_font: $header-fontsize;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
      &:hover {
        color: $header-link-hover;
      }
    }
  }
  .is-active {
    color: $primary !important;
  }
</style>
