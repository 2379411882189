<!-- @format -->

<template>
  <div id="index" style="pointer-events: none; position: relative;">
    <div class="desktop-top">
      <div class="desktop-top__wrapper">
        <div
          class="desktop-top__right"
          :height="this.$store.getters['Window/isSize'].height - 90 + 'px'"
          :width="this.$store.getters['Window/isSize'].height - 90 + 'px'"
        >
          <div
            v-scroll-reveal="{
              delay: 750,
              origin: 'top',
              distance: '50px',
            }"
            class="desktop-top__ikone"
            :class="$mq"
          >
            <img src="@/assets/images/top/ikone.png" alt="" />
          </div>
          <video
            v-scroll-reveal="{
              delay: 500,
            }"
            src="@/assets/videos/sizzle.mp4"
            autoplay
            loop
            muted
            :height="this.$store.getters['Window/isSize'].height - 90 + 'px'"
            :width="this.$store.getters['Window/isSize'].height - 90 + 'px'"
          />
          <div
            v-scroll-reveal="{
              delay: 800,
              origin: 'left',
              distance: '50px',
            }"
            class="desktop-top__trailer"
            :class="$mq"
          >
            <div v-if="$config.youtube.display">
              <div @click="ytVideo" class="open-trailer">
                Trailer ansehen
              </div>
            </div>
          </div>
        </div>
        <div class="desktop-top__content" :class="$mq">
          <div>
            <div
              v-scroll-reveal="{
                delay: 750,
                origin: 'top',
                distance: '50px',
              }"
              class="desktop-top__cites"
              :class="$mq"
            >
              <BaseCitesFade class="" />
            </div>
            <div
              v-scroll-reveal="{
                delay: 750,
                origin: 'top',
                distance: '50px',
              }"
              class="desktop-top__cast"
              :class="$mq"
            >
              <img src="@/assets/images/top/cast.png" alt="" />
            </div>
            <div
              v-scroll-reveal="{
                delay: 250,
                origin: 'top',
                distance: '50px',
              }"
              class="desktop-top__tt"
              :class="$mq"
            >
              <img :src="require(`@/assets/images/` + $config.tt)" alt="" />
            </div>
            <div
              v-scroll-reveal="{
                delay: 500,
                origin: 'bottom',
                distance: '50px',
              }"
              class="desktop-top__von"
              :class="$mq"
            >
              <img src="@/assets/images/top/von.png" alt="" />
            </div>
            <div class="kinostart" :class="$mq">
              <div class="top" :class="$mq">
                <div>ab 10.11. auf DVD</div>
                & Video-on-demand
              </div>
              <div class="bottom" :class="$mq">
                ab 31.10. als digitaler Download
              </div>
            </div>
          </div>
          <div
            v-scroll-reveal="{
              delay: 750,
              origin: 'top',
              distance: '50px',
            }"
            class="desktop-top__praedi"
            :class="$mq"
          >
            <img src="@/assets/images/top/praedi.png" alt="" />
          </div>
          <div
            v-scroll-reveal="{
              delay: 750,
              origin: 'top',
              distance: '50px',
            }"
            class="desktop-top__packshot"
            :class="$mq"
          >
            <a
              href="https://www.amazon.de/Helmut-Newton-Bad-Beautiful/dp/B08CMDMM87/ref=sr_1_6?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Helmut+Newton&qid=1601904908&sr=8-6&tag=filmweltverle-21"
              target="_blank"
              ><img src="@/assets/images/top/packshot.png" alt=""
            /></a>
          </div>
          <div
            v-scroll-reveal="{
              delay: 750,
              origin: 'top',
              distance: '50px',
            }"
            class="desktop-top__preis"
            :class="$mq"
          >
            <img src="@/assets/images/top/preis.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      ratio: {
        type: Number,
        default: 1.78,
      },
      trailer: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      ytVideo() {
        EventBus.$emit('open-yt')
      },
    },
  }
</script>

<style lang="scss">
  .desktop-top {
    $self: &;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    &__bg {
      position: fixed;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: black;
    }
    &__wrapper {
      position: relative;
      padding-top: $header-height;
      height: 100%;
      display: flex;
      flex-direction: row;
      #{$self}__right {
        #{$self}__ikone {
          position: absolute;
          top: 2%;
          left: 8%;
          width: 13%;
          img {
            width: 100%;
          }
          &.xl {
            width: 15%;
          }
        }
        position: relative;
        padding-left: 57px;
        video {
          max-width: none;
          object-fit: cover;
        }
        #{$self}__trailer {
          pointer-events: auto;
          cursor: pointer;
          display: block;
          position: absolute;
          bottom: 200px;
          left: -60px;
          text-transform: uppercase;
          background-color: $primary;
          font-weight: 900;
          font-size: 35px;
          padding: 5px 15px 5px 85px;
        }
      }
      #{$self}__content {
        position: relative;
        height: 90%;
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .cites {
          text-align: center;
          width: 100%;
        }
        #{$self}__praedi {
          position: absolute;
          top: 2%;
          right: 4%;
          width: 12%;
          img {
            width: 100%;
          }
          &.lg {
            width: 25%;
          }
          &.xl {
            width: 20%;
          }
        }
        #{$self}__packshot {
          position: absolute;
          pointer-events: auto;
          bottom: 8%;
          left: -5%;
          width: 25%;
          img {
            width: 100%;
          }
          &.lg {
            width: 35%;
            left: -25%;
          }
          &.xl {
            width: 35%;
            left: -25%;
          }
        }
        #{$self}__preis {
          position: absolute;
          top: 2%;
          left: 4%;
          width: 15%;
          img {
            width: 100%;
          }
          &.lg {
            width: 30%;
          }
          &.xl {
            width: 27%;
          }
        }
        #{$self}__cites {
          margin: 0 auto;
          width: 100%;
          margin-bottom: 5%;
          .flickity-slider {
            width: 100% !important;
            margin: 0 auto !important;
          }
          @-moz-document url-prefix() {
            .flickity-slider {
              position: absolute;
              left: -15px !important;
            }
          }
        }
        #{$self}__cast {
          margin: 0 auto;
          width: 90%;
          margin-bottom: 5%;
        }
        #{$self}__tt {
          margin: 0 auto;
          width: 90%;
        }
        #{$self}__von {
          margin: 0 auto;
          width: 35%;
          margin-top: 5%;
          margin-bottom: 5%;
        }
        .kinostart {
          position: relative;
          text-align: center;
          text-transform: uppercase;
          color: $primary;
          font-weight: 900;
          &.sm,
          &.md {
            padding-top: 10px;
            background-color: $primary;
          }
          .top {
            &.lg,
            &.xl,
            &.xxl {
              line-height: 1;
              $min_width: 1024px;
              $max_width: 2560px;
              $min_font: 30px;
              $max_font: 44px;
              @include fluid-type($min_width, $max_width, $min_font, $max_font);
            }
            &.sm,
            &.md {
              $min_width: 300px;
              $max_width: 1023px;
              $min_font: 22px;
              $max_font: 45px;
              @include fluid-type($min_width, $max_width, $min_font, $max_font);
            }
          }
          .bottom {
            font-weight: 700;
            &.lg,
            &.xl {
              $min_width: 1024px;
              $max_width: 2560px;
              $min_font: 15px;
              $max_font: 40px;
              @include fluid-type($min_width, $max_width, $min_font, $max_font);
            }
            &.xxl {
              $min_width: 1024px;
              $max_width: 2560px;
              $min_font: 10px;
              $max_font: 31px;
              @include fluid-type($min_width, $max_width, $min_font, $max_font);
            }
            &.sm,
            &.md {
              $min_width: 300px;
              $max_width: 1023px;
              $min_font: 22px;
              $max_font: 45px;
              @include fluid-type($min_width, $max_width, $min_font, $max_font);
            }
          }
        }
        @media screen and (max-height: 820px) {
          #{$self}__cites {
            margin: 0 auto;
            width: 100%;
            margin-bottom: 3%;
          }
          #{$self}__cast {
            margin: 0 auto;
            width: 80%;
            margin-bottom: 3%;
          }
          #{$self}__tt {
            margin: 0 auto;
            width: 80%;
          }
          #{$self}__von {
            margin: 0 auto;
            width: 25%;
            margin-top: 3%;
            margin-bottom: 5%;
          }
        }
      }
    }
  }
</style>
