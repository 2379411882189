<!-- @format -->
<template>
  <div class="carousel">
    <slot></slot>
  </div>
</template>

<script>
  import Flickity from 'flickity'
  import 'flickity-fullscreen'
  import 'flickity-fade'
  import 'flickity/dist/flickity.min.css'

  export default {
    props: {
      wraparound: { type: Boolean, default: true },
      autoplay: { type: Boolean, default: false },
      preview: { type: Boolean, default: false },
      fullscreen: { type: Boolean, default: false },
      fade: { type: Boolean, default: false },
      dots: { type: Boolean, default: false },
    },
    computed: {
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
    },
    mounted() {
      window.addEventListener('load', () => {
        window.dispatchEvent(new Event('resize'))
      })
      // eslint-disable-next-line
        let flkty = new Flickity('.source', {
        wrapAround: this.wraparound,
        autoPlay: this.autoplay,
        fullscreen: this.fullscreen,
        fade: this.fade,
        pageDots: this.dots,
        setGallerySize: true,
      })
    },
  }
</script>

<style lang="scss">
  .carousel {
    $self: &;
  }
  .carousel-cell {
    width: 32%;
    margin-left: 61px;
  }
  .flickity-button {
    position: absolute;
  }

  /* icon color */
  .flickity-button-icon {
    fill: white;
  }
  .flickity-button-icon:hover {
    fill: $tertiary;
  }
  /* no circle */
  .flickity-prev-next-button,
  .flickity-prev-next-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  .flickity-prev-next-button {
    width: 2%;
    height: 13%;
    border-radius: 0;
  }

  .flickity-prev-next-button.previous {
    left: 0;
  }

  .flickity-prev-next-button.next {
    right: 0;
  }

  /* Flickity fullscreen v1.0.1
------------------------- */

  .flickity-enabled.is-fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 99999;
    .flickity-prev-next-button {
      width: 2%;
      height: 100px;
    }
    .pictures {
      border: none;
      width: 100%;
    }
    .carousel-cell {
      width: 100%;
      margin-left: 0;
    }
  }

  .flickity-enabled.is-fullscreen .flickity-page-dots {
    bottom: 10px;
  }

  .flickity-enabled.is-fullscreen .flickity-page-dots .dot {
    background: white;
  }

  /* prevent page scrolling when flickity is fullscreen */
  html.is-flickity-fullscreen {
    overflow: hidden;
  }

  /* ---- flickity-fullscreen-button ---- */

  .flickity-fullscreen-button {
    position: absolute;
    display: block;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    //background-color: rgba(255, 255, 255, 0.6);
  }

  /* right-to-left */
  .flickity-rtl .flickity-fullscreen-button {
    right: auto;
    left: 10px;
  }

  .flickity-fullscreen-button-exit {
    display: none;
  }

  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-exit {
    display: block;
  }
  .flickity-enabled.is-fullscreen .flickity-fullscreen-button-view {
    display: none;
  }

  .flickity-fullscreen-button .flickity-button-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 4px;
    top: 4px;
  }
  @media (max-width: 960px) {
    .flickity-prev-next-button {
      width: 75px;
      height: 75px;
    }
    .flickity-prev-next-button.previous {
      left: 0px;
    }

    .flickity-prev-next-button.next {
      right: 0px;
    }

    .flickity-fullscreen-button {
      display: none;
    }
  }
</style>
