<!-- @format -->

<template>
  <div>
    <DesktopHeader :social="true" />
    <DesktopTop :ratio="ratio" :trailer="trailer" :kinostart="kinostart" />
    <Inhalt />
    <Fotos />
    <Cast />
    <Interview />
    <BaseFooter :billing="true" :kinostart="kinostart" />
    <BaseYoutube :youtube="$config.youtube" :margin="100" />
  </div>
</template>

<script>
  import Inhalt from '@/views/Inhalt.vue'
  import Fotos from '@/views/Fotos.vue'
  import Cast from '@/views/Cast.vue'
  import Interview from '@/views/Interview.vue'

  import moment from 'moment'

  export default {
    components: {
      Inhalt,
      Fotos,
      Interview,
      Cast,
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    methods: {
      logout() {
        this.$store.dispatch('Auth/userSignOut')
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
