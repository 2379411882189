<!-- @format -->
<template>
  <mq-layout :mq="['sm', 'md']">
    <div class="mobile-top" id="index">
      <div class="dvd-link">
        <a
          href="https://www.amazon.de/Helmut-Newton-Bad-Beautiful/dp/B08CMDMM87/ref=sr_1_6?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Helmut+Newton&qid=1601904908&sr=8-6&tag=filmweltverle-21"
          target="_blank"
          style="display: block; height: 100%; width: 100%;"
        ></a>
      </div>
      <img src="@/assets/images/mobile/bg_top.jpg" alt="" />
    </div>
  </mq-layout>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'

  export default {
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      showKinofinder: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>

<style lang="scss">
  .no-scroll {
    overflow: hidden;
    height: 100vh;
    scrollbar-width: 0;
  }
  .fullScreen {
    height: 100vh;
  }
  .mobile-top {
    $self: &;
    position: relative;
    margin-top: 50px;
    .dvd-link {
      position: absolute;
      left: 0;
      top: 40%;
      height: 25%;
      width: 40%;
    }
    &__kinostart {
      position: absolute;
      text-align: center;
      color: $primary;
      bottom: 6%;
      left: 0;
      font-weight: 700;
      text-transform: uppercase;
      width: 100%;
      &.sm,
      &.md {
        $min_width: 300px;
        $max_width: 1023px;
        $min_font: 30px;
        $max_font: 85px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
    }
  }
  .flickity-viewport {
    .flickity-slider {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
