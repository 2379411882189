var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cast",attrs:{"id":"cast"}},[_c('h1',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
      delay: 250,
      origin: 'left',
      distance: '200px',
    }),expression:"{\n      delay: 250,\n      origin: 'left',\n      distance: '200px',\n    }",modifiers:{"reset":true}}],domProps:{"innerHTML":_vm._s(_vm.$texte.cast.h1)}}),_c('div',{staticClass:"cast__wrapper"},_vm._l((_vm.$texte.cast.besetzung),function(reihe){return _c('div',{key:reihe.links,staticClass:"row"},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
          delay: 750,
          origin: 'left',
          distance: '50px',
        }),expression:"{\n          delay: 750,\n          origin: 'left',\n          distance: '50px',\n        }",modifiers:{"reset":true}}],staticClass:"left"},[_c('div',{staticClass:"left-content",domProps:{"innerHTML":_vm._s(reihe.links)}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
          delay: 750,
          origin: 'right',
          distance: '50px',
        }),expression:"{\n          delay: 750,\n          origin: 'right',\n          distance: '50px',\n        }",modifiers:{"reset":true}}],staticClass:"right"},[_c('div',{staticClass:"right-content",domProps:{"innerHTML":_vm._s(reihe.rechts)}})])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }