<!-- @format -->

<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__tt" :class="$mq">
        <img :src="require(`@/assets/images/` + $config.tt_small)" alt="" />
      </div>
      <div class="kinostart" :class="$mq">
        <div class="top" :class="$mq">
          ab 10.11. auf DVD <br />
          & Video-on-demand
        </div>
        <div class="bottom" :class="$mq">
          ab 31.10. als digitaler Download
        </div>
      </div>
      <div v-if="billing" class="footer__billing" :class="$mq">
        <img src="@/assets/images/billing.png" alt="" />
      </div>
      <!-- SECTION Footer Company -> Chooses Footer by $config.verantwortlich -> look @ computed -->
      <component
        class="footer__company"
        :is="companyFooter"
        :teilnahmebedingung="teilnahmebedingung"
      ></component>
      <component class="footer__social" :is="companySocial"></component>
    </div>
    <div class="cb-restore"></div>
    <div class="info" :class="$mq">
      * Der mit Sternchen (*) gekennzeichnete Link ist ein sogenannter
      Affiliate-Link. Wenn Sie auf einen Affiliate-Link klicken und über diesen
      Link einkaufen, erhält Filmwelt Verleihagentur GmbH vom betreffenden
      Online-Shop oder Anbieter eine Provision. Für Sie verändert sich der Preis
      nicht.
    </div>
  </footer>
</template>

<script>
  export default {
    props: {
      billing: {
        type: Boolean,
        default: true,
      },
      youtubenote: {
        type: Boolean,
        default: true,
      },
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    computed: {
      companyFooter() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Footer.vue`
          )
      },
      companySocial() {
        return () =>
          import(
            `@/components/Base/Elements/Companies/${this.$config.verantwortlich.toLowerCase()}/Social.vue`
          )
      },
    },
  }
</script>

<style lang="scss">
  .footer {
    $self: &;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    background-color: $primary;
    padding-bottom: 8rem;
    &__content {
      position: relative;
      margin: 0 auto;
      padding-top: 2rem;
      #{$self}__tt {
        margin: 40px auto 0 auto;
        &.sm,
        &.md {
          padding: 0 5%;
          margin: 40px auto 0 auto;
        }
      }
      .kinostart {
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: black;
        font-weight: 900;
        padding: 10px 0;
        &.sm,
        &.md {
          padding-top: 10px;
          background-color: $primary;
        }
        .top {
          &.lg,
          &.xl,
          &.xxl {
            line-height: 1;
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 30px;
            $max_font: 44px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
          &.sm,
          &.md {
            line-height: 1;
            $min_width: 300px;
            $max_width: 1023px;
            $min_font: 22px;
            $max_font: 45px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
        }
        .bottom {
          font-weight: 700;
          &.lg,
          &.xl {
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 15px;
            $max_font: 40px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
          &.xxl {
            $min_width: 1024px;
            $max_width: 2560px;
            $min_font: 10px;
            $max_font: 31px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
          &.sm,
          &.md {
            $min_width: 300px;
            $max_width: 1023px;
            $min_font: 5px;
            $max_font: 36px;
            @include fluid-type($min_width, $max_width, $min_font, $max_font);
          }
        }
      }
    }
    #{$self}__billing {
      margin-top: 10px;
      margin-bottom: 30px;
      &.sm,
      &.md {
        margin-top: 0;
        margin-bottom: 20px;
      }
      &.sm,
      &.md,
      &.lg,
      &.xl {
        padding: 0 5%;
      }
    }
    .info {
      width: 40%;
      margin: 0 auto;
      font-size: 0.7rem;
      &.sm,
      &.md {
        width: 60%;
      }
    }
  }
</style>
