<!-- @format -->

<template>
  <div class="cast" id="cast">
    <h1
      v-scroll-reveal.reset="{
        delay: 250,
        origin: 'left',
        distance: '200px',
      }"
      v-html="$texte.cast.h1"
    ></h1>
    <div class="cast__wrapper">
      <div
        v-for="reihe in $texte.cast.besetzung"
        :key="reihe.links"
        class="row"
      >
        <div
          class="left"
          v-scroll-reveal.reset="{
            delay: 750,
            origin: 'left',
            distance: '50px',
          }"
        >
          <div class="left-content" v-html="reihe.links"></div>
        </div>
        <div
          class="right"
          v-scroll-reveal.reset="{
            delay: 750,
            origin: 'right',
            distance: '50px',
          }"
        >
          <div class="right-content" v-html="reihe.rechts"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .cast {
    $self: &;
    position: relative;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -120px;
    z-index: 100;
    h1 {
      margin-bottom: 47px;
    }
    &__wrapper {
      overflow: hidden;
      width: 100%;
      img {
        max-width: none !important;
      }
      .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        p {
          font-size: 17px;
          color: $primary;
        }
        .rot {
          color: $primary;
        }
        .fett {
          font-weight: 700;
        }
        .left {
          width: 40%;
          .left-content {
            text-align: right;
            margin-right: 20px;
          }
        }
        .right {
          width: 40%;
          .right-content {
            text-align: left;
            margin-left: 20px;
          }
        }
      }
    }
  }
</style>
