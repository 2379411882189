<!-- @format -->

<template>
  <div class="inhalt" id="inhalt">
    <div class="inhalt__wrapper">
      <h1
        v-scroll-reveal.reset="{
          delay: 250,
          origin: 'left',
          distance: '50px',
        }"
        v-html="$texte.inhalt.h1"
      ></h1>
      <div
        v-scroll-reveal.reset="{ delay: 500, origin: 'left', distance: '50px' }"
        class="inhalt__text"
        v-html="$texte.inhalt.text"
        :class="$mq"
      ></div>
      <BaseImage
        v-scroll-reveal.reset="{
          delay: 500,
          origin: 'right',
          distance: '100px',
        }"
        class="inhalt__image"
        image="inhalt/picture.jpg"
        text="Selbstporträt, Monte Carlo, 1993"
        copyright="© Foto: Helmut Newton, Helmut Newton Estate / Courtesy Helmut Newton Foundation"
      />
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss">
  .inhalt {
    $self: &;
    position: relative;
    padding-bottom: 40px;
    background-color: black;
    height: 900px;
    &__wrapper {
      overflow: hidden;
      h1 {
        position: absolute;
        top: 180px;
        left: 50%;
        margin-left: -250px;
        z-index: 100;
      }
      img {
        max-width: none !important;
      }
      #{$self}__image {
        position: absolute;
        top: 78px;
        left: 50%;
      }
      #{$self}__text {
        width: 420px;
        text-align: right;
        position: absolute;
        left: 50%;
        top: 282px;
        margin-left: -470px;
      }
    }
  }
</style>
