var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"pointer-events":"none","position":"relative"},attrs:{"id":"index"}},[_c('div',{staticClass:"desktop-top"},[_c('div',{staticClass:"desktop-top__wrapper"},[_c('div',{staticClass:"desktop-top__right",attrs:{"height":this.$store.getters['Window/isSize'].height - 90 + 'px',"width":this.$store.getters['Window/isSize'].height - 90 + 'px'}},[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
            delay: 750,
            origin: 'top',
            distance: '50px',
          }),expression:"{\n            delay: 750,\n            origin: 'top',\n            distance: '50px',\n          }"}],staticClass:"desktop-top__ikone",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/ikone.png"),"alt":""}})]),_c('video',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
            delay: 500,
          }),expression:"{\n            delay: 500,\n          }"}],attrs:{"src":require("@/assets/videos/sizzle.mp4"),"autoplay":"","loop":"","muted":"","height":this.$store.getters['Window/isSize'].height - 90 + 'px',"width":this.$store.getters['Window/isSize'].height - 90 + 'px'},domProps:{"muted":true}}),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
            delay: 800,
            origin: 'left',
            distance: '50px',
          }),expression:"{\n            delay: 800,\n            origin: 'left',\n            distance: '50px',\n          }"}],staticClass:"desktop-top__trailer",class:_vm.$mq},[(_vm.$config.youtube.display)?_c('div',[_c('div',{staticClass:"open-trailer",on:{"click":_vm.ytVideo}},[_vm._v(" Trailer ansehen ")])]):_vm._e()])]),_c('div',{staticClass:"desktop-top__content",class:_vm.$mq},[_c('div',[_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
              delay: 750,
              origin: 'top',
              distance: '50px',
            }),expression:"{\n              delay: 750,\n              origin: 'top',\n              distance: '50px',\n            }"}],staticClass:"desktop-top__cites",class:_vm.$mq},[_c('BaseCitesFade',{})],1),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
              delay: 750,
              origin: 'top',
              distance: '50px',
            }),expression:"{\n              delay: 750,\n              origin: 'top',\n              distance: '50px',\n            }"}],staticClass:"desktop-top__cast",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/cast.png"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
              delay: 250,
              origin: 'top',
              distance: '50px',
            }),expression:"{\n              delay: 250,\n              origin: 'top',\n              distance: '50px',\n            }"}],staticClass:"desktop-top__tt",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/" + _vm.$config.tt),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
              delay: 500,
              origin: 'bottom',
              distance: '50px',
            }),expression:"{\n              delay: 500,\n              origin: 'bottom',\n              distance: '50px',\n            }"}],staticClass:"desktop-top__von",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/von.png"),"alt":""}})]),_c('div',{staticClass:"kinostart",class:_vm.$mq},[_c('div',{staticClass:"top",class:_vm.$mq},[_c('div',[_vm._v("ab 10.11. auf DVD")]),_vm._v(" & Video-on-demand ")]),_c('div',{staticClass:"bottom",class:_vm.$mq},[_vm._v(" ab 31.10. als digitaler Download ")])])]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
            delay: 750,
            origin: 'top',
            distance: '50px',
          }),expression:"{\n            delay: 750,\n            origin: 'top',\n            distance: '50px',\n          }"}],staticClass:"desktop-top__praedi",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/praedi.png"),"alt":""}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
            delay: 750,
            origin: 'top',
            distance: '50px',
          }),expression:"{\n            delay: 750,\n            origin: 'top',\n            distance: '50px',\n          }"}],staticClass:"desktop-top__packshot",class:_vm.$mq},[_vm._m(0)]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal",value:({
            delay: 750,
            origin: 'top',
            distance: '50px',
          }),expression:"{\n            delay: 750,\n            origin: 'top',\n            distance: '50px',\n          }"}],staticClass:"desktop-top__preis",class:_vm.$mq},[_c('img',{attrs:{"src":require("@/assets/images/top/preis.png"),"alt":""}})])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.amazon.de/Helmut-Newton-Bad-Beautiful/dp/B08CMDMM87/ref=sr_1_6?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Helmut+Newton&qid=1601904908&sr=8-6&tag=filmweltverle-21","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/images/top/packshot.png"),"alt":""}})])}]

export { render, staticRenderFns }