<!-- @format -->
<template>
  <div style="">
    <div class="base-image" :style="textStyle">
      <img :src="require(`@/assets/images/${image}`)" alt="" />
      <div class="base-image__text" v-html="text"></div>
      <div class="base-image__copyright" v-html="copyright"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      image: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: 'text',
      },
      copyright: {
        type: String,
        default: 'copyright',
      },
      align: {
        type: String,
        default: 'left',
      },
    },
    computed: {
      textStyle() {
        return `--textAlign: ${this.align};
                width: ${this.textWidth};`
      },
      textWidth() {
        return this.align === 'left' ? '70%' : '100%'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .base-image {
    --textAlign: left;
    position: relative;
    text-align: var(--textAlign);

    $self: &;
    img {
      max-width: none !important;
    }
    &__text {
      margin-top: 15px;
    }
    &__copyright {
      font-size: 14px;
      color: #9a9a9a;
      overflow: hidden;
    }
  }
</style>
