<!-- @format -->

<template>
  <div class="interview">
    <div class="interview__header" :class="$mq">
      <div class="interview__headline" :class="$mq">
        <h1
          v-scroll-reveal.reset="{
            delay: 250,
            origin: 'left',
            distance: '50px',
          }"
          v-html="$texte.interview.h1"
        ></h1>
        <div
          v-scroll-reveal.reset="{
            delay: 500,
            origin: 'right',
            distance: '50px',
          }"
          class="interview__von"
          v-html="$texte.interview.von"
        ></div>
        <div
          v-scroll-reveal.reset="{
            delay: 750,
            origin: 'left',
            distance: '50px',
          }"
          class="interview__name"
          v-html="$texte.interview.name"
        ></div>
      </div>
      <div
        v-scroll-reveal.reset="{
          delay: 500,
        }"
        class="interview__copyright"
      >
        Gero von Boehm und Isabella Rosselini
        <span>© Foto: Pierre Nativel, LUPA FILM</span>
      </div>
    </div>
    <div
      v-scroll-reveal.reset="{
        delay: 1000,
        origin: 'top',
        distance: '50px',
      }"
      class="interview__text"
      :class="$mq"
      id="interview"
    >
      <div v-if="!open">
        <div class="c-2" v-html="$texte.interview.text"></div>
        <div
          v-if="!open"
          :class="$mq"
          class="interview__btn btn-pos"
          @click="open = true"
        >
          <PlusIcon /> Weiterlesen
        </div>
      </div>
      <div v-if="open">
        <div class="c-2" v-html="$texte.interview.more"></div>
        <div
          v-if="open"
          :class="$mq"
          class="interview__btn btn-pos"
          @click="open = false"
        >
          <MinusIcon /> Schliessen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PlusIcon from 'vue-material-design-icons/PlusCircle.vue'
  import MinusIcon from 'vue-material-design-icons/MinusCircle.vue'

  export default {
    components: {
      PlusIcon,
      MinusIcon,
    },
    data() {
      return {
        open: false,
      }
    },
  }
</script>

<style lang="scss">
  .interview {
    --width: 1400px;
    $self: &;
    position: relative;
    text-align: left;
    background: black url($assets + 'images/interview/bg.jpg') no-repeat top
      center;
    padding-top: 688px;
    &__header {
      display: flex;
      flex-direction: row;
      width: var(--width);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &.lg {
        width: 960px;
        flex-direction: column;
      }
      &.xl {
        width: 1200px;
      }
      #{$self}__headline {
        h1 {
          margin-left: 100px;
          margin-bottom: 20px;
        }
        #{$self}__von {
          font-size: 23px;
          font-weight: 900;
          padding-left: 170px;
        }
        #{$self}__name {
          text-transform: uppercase;
          color: $primary;
          font-size: 36px;
          font-weight: 900;
          line-height: 1;
          padding-left: 100px;
        }
        #{$self}__teaser {
          margin-top: 15px;
        }
      }
      #{$self}__copyright {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;
        span {
          display: block;
          font-size: 14px;
          color: $secondary;
        }
      }
    }
    &__text {
      text-align: left;
      margin: 0 auto;
      padding: 40px 0 80px 0;
      width: var(--width);
      &.lg {
        width: 960px;
      }
      &.xl {
        width: 1200px;
      }
      span {
        font-weight: 700;
        color: $primary;
      }
    }
    &__btn {
      color: $primary;
      text-transform: uppercase;
      cursor: pointer;
      display: inline-block;
      position: relative;
      padding-left: 15px;
      margin-left: 50%;
      &.lg {
        padding-left: 0;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
</style>
