<!-- @format -->
<template>
  <div class="fotos" id="fotos">
    <BaseImage
      v-for="(image, index) in $texte.fotos"
      :key="image.text"
      class="fotos__image"
      :class="`fotos__image-${image.name}`"
      :image="`fotos/${image.name}.jpg`"
      :text="image.text"
      :copyright="image.copyright"
      :align="image.align"
      v-scroll-reveal.reset="{
        delay: 500,
        origin: index % 2 ? 'left' : 'right',
        distance: '50px',
      }"
    />
    <h1
      class="fotos__image fotos__headline"
      v-scroll-reveal.reset="{ delay: 500, origin: 'left', distance: '50px' }"
    >
      Fotos
    </h1>
    <BaseModalGallery :index="index" />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        index: null,
      }
    },
    methods: {
      openGallery(number) {
        this.index = number - 1
      },
    },
  }
</script>

<style lang="scss" scoped>
  .fotos {
    $self: &;
    position: relative;
    height: 3065px;
    z-index: 10;
    &__image {
      position: absolute;
      left: 50%;
    }
    &__headline {
      top: 719px;
      margin-left: 152px;
    }
    &__image-f1 {
      top: 2px;
      margin-left: -634px;
    }
    &__image-f2 {
      top: 206px;
      margin-left: 226px;
      & > img {
        overflow: hidden;
        min-width: 800px !important;
      }
    }
    &__image-f3 {
      top: 687px;
      margin-left: -437px;
    }
    &__image-f4 {
      top: 1051px;
      margin-left: 159px;
    }
    &__image-f5 {
      top: 1274px;
      margin-left: -524px;
    }
    &__image-f6 {
      top: 1637px;
      margin-left: 165px;
    }
    &__image-f7 {
      top: 2258px;
      margin-left: -481px;
    }
    &__image-f8 {
      top: 2462px;
      margin-left: 146px;
    }
  }
</style>
