<!-- @format -->

<template>
  <flickity ref="flickity" :options="flickityOptions">
    <div v-for="cite in $config.cites" :key="cite.text" class="carousel-cell">
      <cite class="base-cite" :class="$mq">
        <p class="base-cite__text" v-text="cite.text" :class="$mq" />
        <p class="base-cite__author" v-text="cite.author" :class="$mq" />
      </cite>
    </div>
  </flickity>
</template>

<script>
  import Flickity from 'vue-flickity'
  require('flickity-fade')

  export default {
    components: {
      Flickity,
    },
    props: {
      kinostart: {
        type: String,
        default: 'Releaseline',
      },
    },
    data() {
      return {
        flickityOptions: {
          initialIndex: 1,
          prevNextButtons: false,
          pageDots: false,
          autoPlay: true,
          fade: true,
          draggable: false,
        },
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.flickity.resize()
      })
    },
  }
</script>

<style lang="scss" scoped>
  .carousel-cell {
    width: 100% !important;
    margin: 0 auto;
  }
  .base-cite {
    $self: &;
    width: 100% !important;
    &__text {
      font-family: 'Heebo', sans-serif;
      font-style: normal;
      font-weight: 500;
      color: white;
      line-height: 1.5;
      margin-bottom: 15px;
      text-align: center;
      margin: 0 auto;
      width: 80% !important;
      &.lg {
        width: 60% !important;
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 9px;
        $max_font: 40px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.xl {
        width: 50% !important;
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 10px;
        $max_font: 40px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.xxl {
        width: 70% !important;
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 5px;
        $max_font: 30px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.sm,
      &.md {
        font-size: 1rem;
        padding-top: 30px;
        width: 50% !important;
      }
    }
    &__author {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      color: #d52d2d;
      margin-top: 5px;
      &.lg {
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 5px;
        $max_font: 35px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.xl {
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 3px;
        $max_font: 38px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.xxl {
        $min_width: 1024px;
        $max_width: 2560px;
        $min_font: 3px;
        $max_font: 23px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
      }
      &.sm,
      &.md {
        font-size: 0.8rem;
      }
    }
  }
</style>
