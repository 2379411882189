var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"interview"},[_c('div',{staticClass:"interview__header",class:_vm.$mq},[_c('div',{staticClass:"interview__headline",class:_vm.$mq},[_c('h1',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
          delay: 250,
          origin: 'left',
          distance: '50px',
        }),expression:"{\n          delay: 250,\n          origin: 'left',\n          distance: '50px',\n        }",modifiers:{"reset":true}}],domProps:{"innerHTML":_vm._s(_vm.$texte.interview.h1)}}),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
          delay: 500,
          origin: 'right',
          distance: '50px',
        }),expression:"{\n          delay: 500,\n          origin: 'right',\n          distance: '50px',\n        }",modifiers:{"reset":true}}],staticClass:"interview__von",domProps:{"innerHTML":_vm._s(_vm.$texte.interview.von)}}),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
          delay: 750,
          origin: 'left',
          distance: '50px',
        }),expression:"{\n          delay: 750,\n          origin: 'left',\n          distance: '50px',\n        }",modifiers:{"reset":true}}],staticClass:"interview__name",domProps:{"innerHTML":_vm._s(_vm.$texte.interview.name)}})]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
        delay: 500,
      }),expression:"{\n        delay: 500,\n      }",modifiers:{"reset":true}}],staticClass:"interview__copyright"},[_vm._v(" Gero von Boehm und Isabella Rosselini "),_c('span',[_vm._v("© Foto: Pierre Nativel, LUPA FILM")])])]),_c('div',{directives:[{name:"scroll-reveal",rawName:"v-scroll-reveal.reset",value:({
      delay: 1000,
      origin: 'top',
      distance: '50px',
    }),expression:"{\n      delay: 1000,\n      origin: 'top',\n      distance: '50px',\n    }",modifiers:{"reset":true}}],staticClass:"interview__text",class:_vm.$mq,attrs:{"id":"interview"}},[(!_vm.open)?_c('div',[_c('div',{staticClass:"c-2",domProps:{"innerHTML":_vm._s(_vm.$texte.interview.text)}}),(!_vm.open)?_c('div',{staticClass:"interview__btn btn-pos",class:_vm.$mq,on:{"click":function($event){_vm.open = true}}},[_c('PlusIcon'),_vm._v(" Weiterlesen ")],1):_vm._e()]):_vm._e(),(_vm.open)?_c('div',[_c('div',{staticClass:"c-2",domProps:{"innerHTML":_vm._s(_vm.$texte.interview.more)}}),(_vm.open)?_c('div',{staticClass:"interview__btn btn-pos",class:_vm.$mq,on:{"click":function($event){_vm.open = false}}},[_c('MinusIcon'),_vm._v(" Schliessen ")],1):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }